import { LicensePlateType } from "src/states/serviceFlow/vehicleActions"

import { isInternationalLicensePlate } from "./isInternationalLicensePlate"

export type LicensePlate = {
  type: LicensePlateType
  internationalNumber?: string
  region?: string
  letters?: string
  number?: string
}

export const parseLicensePlate = (
  value?: string,
  licensePlateType?: LicensePlateType,
): LicensePlate => {
  let licensePlate: LicensePlate = {
    type: "INTERNATIONAL",
    internationalNumber: value?.trim(),
  }

  if (!value) {
    return { type: "GERMAN" }
  }

  if (isInternationalLicensePlate(licensePlateType)) {
    return licensePlate
  }

  const splittedLicensePlate = value.trim().split(/[\s-]+/)
  if (splittedLicensePlate.length === 3) {
    return {
      type: "GERMAN",
      region: splittedLicensePlate[0],
      letters: splittedLicensePlate[1],
      number: splittedLicensePlate[2],
    }
  }

  return licensePlate
}
