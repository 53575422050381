import { forwardRef, ReactNode } from "react"

import { BaseLink } from "src/components/common/base-link"
import { create } from "src/helpers/bem"

import { useIsMounted } from "src/hooks/isMounted"

import styles from "./BaseCard.module.scss"

const bem = create(styles, "BaseCard")

export type BaseCardProps = {
  selected?: boolean
  className?: string
  disabled?: boolean
  noMargin?: boolean
  href?: string
  onClick?: () => void
  children?: ReactNode
  dataCy?: string
}

export const BaseCard = forwardRef<HTMLDivElement, BaseCardProps>(
  (
    {
      selected = false,
      children,
      className,
      disabled = false,
      noMargin = false,
      href,
      onClick,
      dataCy,
    },
    ref,
  ) => {
    const modifiers = {
      [`is-disabled`]: disabled,
      [`is-selected`]: selected,
      [`no-margin`]: noMargin,
    }

    const isMounted = useIsMounted()

    // We need to wait for the browser
    // Otherwise this component returns a hydration error.
    if (!isMounted) return null

    return !!href ? (
      <BaseLink
        data-cy={dataCy}
        ref={ref as React.Ref<HTMLAnchorElement>}
        className={bem(undefined, modifiers, className)}
        href={href}
        aria-disabled={disabled}
        onClick={onClick}
      >
        {children}
      </BaseLink>
    ) : (
      <button
        data-cy={dataCy}
        ref={ref as React.Ref<HTMLButtonElement>}
        className={bem(undefined, modifiers, className)}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
    )
  },
)

BaseCard.displayName = "BaseCard"
