import { FC } from "react"

import { BookingService } from "src/helpers/autoservice-account-backend/types"

import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import { Inquiry } from "src/states/serviceFlow/inquiryActions"

import styles from "./BookingCardServices.module.scss"

const bem = create(styles, "BookingCardServices")

type BookingCardServicesProps = {
  isCancelled?: boolean
  services?: BookingService[]
  inquiry?: Inquiry
}

export const BookingCardServices: FC<BookingCardServicesProps> = ({
  isCancelled,
  services,
  inquiry,
}) => {
  const { messages } = useTranslation()
  const translations = messages.pages.account.index.bookings.card

  return (
    <div>
      {services?.map((service) => {
        return (
          <div
            key={service.internalServiceId}
            className={bem(undefined, { disabled: !!isCancelled })}
          >
            {service.name}
          </div>
        )
      })}
      {!services?.length && inquiry && (
        <div className={bem()}>{translations.inquiry}</div>
      )}
    </div>
  )
}
