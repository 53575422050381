import { BookingStatus } from "src/helpers/autoservice-account-backend/types"

const cancellationStates: BookingStatus[] = [
  "CANCELED",
  "CANCELLATION_REQUESTED",
]

export function getIsBookingCancelled(status: BookingStatus | undefined) {
  if (!status) return false
  return cancellationStates.includes(status)
}
