import { FC } from "react"

import { Tag } from "src/components/common/tag"
import { BookingStatus } from "src/helpers/autoservice-account-backend/types"

import { create } from "src/helpers/bem"
import { getIsBookingCancelled } from "src/helpers/getIsBookingCancelled"
import { useTranslation } from "src/hooks/translation"

import styles from "./BookingCardHeader.module.scss"

const bem = create(styles, "BookingCardHeader")

export type BookingCardHeaderProps = {
  status?: BookingStatus
}

export const BookingCardHeader: FC<BookingCardHeaderProps> = ({ status }) => {
  const { messages } = useTranslation()
  const translations = messages.pages.account.index.bookings.card

  const isCanceled = getIsBookingCancelled(status)

  const isOffer = status === "OFFER_AVAILABLE"
  const isPaymentPending = status === "PAYMENT_PENDING"
  const isMoreDetailsRequired = status === "CUSTOMER_ACTION_NEEDED"

  const isTagAvailable =
    isOffer || isPaymentPending || isMoreDetailsRequired || isCanceled

  if (!isTagAvailable) return

  const getTagTitle = (status?: BookingStatus) => {
    switch (status) {
      case "PAYMENT_PENDING":
        return translations.paymentPending
      case "OFFER_AVAILABLE":
        return translations.offerAvailable
      case "CUSTOMER_ACTION_NEEDED":
        return translations.moreInfoNeeded
      case "CANCELED":
        return translations.cancelled
      case "CANCELLATION_REQUESTED":
        return translations.cancellationRequested
      default:
        return
    }
  }

  return (
    <div className={bem()}>
      <Tag variant="secondary" className={bem("tag")}>
        {getTagTitle(status)}
      </Tag>
    </div>
  )
}
