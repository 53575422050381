import { parseLicensePlate } from "src/components/common/form/vehicle-form/components/LicensePlate/helpers/parseLicensePlate"
import {
  BookingGarage,
  BookingService,
  CarBookingInfo,
} from "src/helpers/autoservice-account-backend/types"
import { AdditionalService } from "src/helpers/autoservice-core-backend/types"
import { ServiceCategory, ServiceFlowData } from "src/states/serviceFlow/types"

type MapBookingInfoToServiceFlowData = (
  garage: BookingGarage,
  services: BookingService[],
  car?: CarBookingInfo,
) => ServiceFlowData

export const mapBookingInfoToServiceFlowData: MapBookingInfoToServiceFlowData =
  (garage, services, car) => {
    const licensePlate = parseLicensePlate(
      car?.licensePlate,
      car?.licensePlateType,
    )

    const data: ServiceFlowData = {
      serviceCategories: services.map((service) => {
        return {
          internalServiceCategoryId: service.id,
          icon: service?.icon,
          service: {
            internalServiceId: service.internalServiceId,
            title: service.name,
          },
          additionalServices:
            service.additionalServices && service.additionalServices.length > 0
              ? service.additionalServices?.map((additionalService) => {
                  return {
                    internalAdditionalServiceId: additionalService.id,
                    title: additionalService.name,
                  } as AdditionalService
                })
              : [],
        } as ServiceCategory
      }),
      detailedGarage: { garage },
      vehicle: {
        ...car,
        licensePlateRegion: licensePlate.region,
        licensePlateLetters: licensePlate.letters,
        licensePlateType: licensePlate.type,
        licensePlateNumber: licensePlate.number,
      },
    }

    return data
  }
