import { Day } from "date-fns"
import { addDays } from "date-fns/addDays"
import { differenceInDays } from "date-fns/differenceInDays"
import { format } from "date-fns/format"
import { isValid } from "date-fns/isValid"
import { parseISO } from "date-fns/parseISO"
import { startOfWeek } from "date-fns/startOfWeek"

import { regex } from "src/const/regex"
import { formatters } from "src/translations/formatters"

import { dateFnsLocale } from "./dateLocales"

/**
 * Returns a date in the format dd.MM.yyyy.
 */
export const getFormattedShortDate = (date: string) => {
  return format(new Date(date), formatters.date.short, {
    locale: dateFnsLocale,
  })
}

/**
 * Returns a date starting 0 o'clock in the morning (0h, 0m, 0s, 0ms).
 */
export const getDateWithoutTime = (date: Date = new Date()) => {
  const dateWithoutTime = new Date(date)

  dateWithoutTime.setHours(0, 0, 0, 0)
  return dateWithoutTime
}

/**
 * Compares to dates if equal of same day.
 */
export const areDaysEqual = (dateA: Date, dateB: Date) => {
  return (
    dateA.getFullYear() === dateB.getFullYear() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getDate() === dateB.getDate()
  )
}

/**
 * Returns the days of a week based on the start day of a week.
 */
export const getWeekDays = (weekStartsOn: Day) => {
  const firstDOW = startOfWeek(new Date(), { weekStartsOn })
  return Array.from(Array(7)).map((_, index) => addDays(firstDOW, index))
}

/**
 * Returns a date in UTC format without seconds (2022-03-08T22:02Z).
 */
export const getUTCDateWithoutSeconds = (date: Date = new Date()) => {
  return date.toISOString().slice(0, -8) + "Z"
}

/**
 * Returns a date in UTC format without seconds (2022-03-08T).
 */
export const getUTCDate = (date: Date = new Date()) => {
  return date.toISOString().slice(0, 11)
}

/**
 * Returns a time from UTC time in HH:mm format like 08:00
 */
export const formatTime = (time: string, formatter: string) => {
  return format(new Date(getUTCDate() + time), formatter)
}

/**
 * Returns date generated from given date in the format MM.yyyy
 */
export const createDatefromMonthYear = (date?: string) => {
  if (!date) return undefined

  // check to deal with historic data
  if (dateIsInValidFormat(date)) return date

  const [month, year] = date.split(".")

  if (!month || !year) return undefined

  return format(new Date(parseInt(year), parseInt(month) - 1), "yyyy-MM-dd")
}

/**
 * Returns date in the format MM.yyyy
 */
export const createShortestDateFormat = (date?: string) => {
  if (!date) return

  if (date.match(regex.dateInputFormat.VALID_DATE)) {
    return date
  }

  if (dateIsInValidFormat(date)) {
    return format(new Date(date), "MM.yyyy")
  }

  return
}

/**
 * Returns number of days between two dates
 */
export const daysBetween = (
  dateFrom: Date = new Date(),
  dateTo: Date = new Date(),
) => {
  return differenceInDays(dateFrom, dateTo) ?? 0
}

/**
 * Returns true if the date is in a valid format yyyy-MM-dd
 */
export const dateIsInValidFormat = (date: string): boolean => {
  const parsedDate = parseISO(date)
  const isDateInValidFormat =
    isValid(parsedDate) && format(parsedDate, "yyyy-MM-dd") === date
  return isDateInValidFormat
}
