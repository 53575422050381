import React from "react"

import { create } from "src/helpers/bem"

import styles from "./CardTag.module.scss"

const bem = create(styles, "CardTag")

export type CardTagProps = {
  label: string
  variant?: "primary" | "success" | "emphasized"
  className?: string
}

/**
  This components is meant to be used inside a card
  to warn about promotions or other specific information regarding that card
*/
export const CardTag = ({
  label,
  variant = "primary",
  className,
}: CardTagProps) => {
  return (
    <div className={bem(undefined, { [variant]: true }, className)}>
      {label}
    </div>
  )
}
