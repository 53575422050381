import { format } from "date-fns/format"
import { FC } from "react"

import { BaseCard } from "src/components/common/baseCard/BaseCard"
import { Button } from "src/components/common/button"
import { CardTag } from "src/components/common/card-tag"
import { Icon } from "src/components/common/icon"

import { BookingCardServices } from "src/components/modules/bookingCard/bookingCardServices"
import { ROUTES, serviceFlowSteps } from "src/config"
import { BookingInfo } from "src/helpers/autoservice-account-backend/types"

import { create } from "src/helpers/bem"
import { checkServiceType } from "src/helpers/checkServiceType"
import { daysBetween } from "src/helpers/date"
import { dateFnsLocale } from "src/helpers/dateLocales"
import { getIsBookingCancelled } from "src/helpers/getIsBookingCancelled"
import { mapBookingInfoToServiceFlowData } from "src/helpers/mapBookingInfoToServiceFlowData"
import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"
import { useTranslation } from "src/hooks/translation"

import { setServiceFlowData } from "src/states/serviceFlow"
import { formatters } from "src/translations/formatters"

import styles from "./BookingCard.module.scss"
import { BookingCardHeader } from "./bookingCardHeader"

const bem = create(styles, "BookingCard")

export type BookingCardProps = BookingInfo & {
  isRecommendation?: boolean
  isOneClickBooking?: boolean
}

export const BookingCard: FC<BookingCardProps> = ({
  id,
  timeslot,
  status,
  services,
  garage,
  car,
  inquiry,
  offerValidUntil,
  isRecommendation,
  isOneClickBooking,
}) => {
  const { messages, formatMessage } = useTranslation()
  const translations = messages.pages.account.index.bookings.card
  const bookingHasInspection = services.some((service) => {
    return checkServiceType.isInspectionService(service.internalServiceId)
  })

  if (isOneClickBooking && bookingHasInspection) {
    return null
  }

  const canBeBookedAgain = isRecommendation || isOneClickBooking

  const formattedDate =
    timeslot &&
    formatMessage(translations.dateFormat, {
      selectedDate: timeslot,
      fullDayName: format(timeslot, formatters.date.fullDay, {
        locale: dateFnsLocale,
      }),
    })

  const formattedTime =
    timeslot &&
    formatMessage(translations.timeFormat, {
      time: timeslot,
    })

  const isOffer = status === "OFFER_AVAILABLE"

  const getFooterText = () => {
    if (timeslot) {
      return `${formattedDate}${formattedTime && `, ${formattedTime}`}`
    }

    if (bookingHasInspection) {
      if (isOffer) {
        const offerValidityDaysLeft = daysBetween(
          new Date(offerValidUntil ?? ""),
        )

        if (offerValidityDaysLeft === 0) return translations.expiresToday

        const daysLeft = `${offerValidityDaysLeft} ${
          offerValidityDaysLeft === 1 ? translations.day : translations.days
        }`

        const validUntilText = formatMessage(translations.validUntil, {
          daysLeft,
        })

        return validUntilText
      }

      if (status === "CUSTOMER_ACTION_NEEDED") {
        return translations.status.customerActionNeeded
      }

      if (status !== "UPCOMING") {
        return translations.noOffer
      }
    }

    if (isRecommendation) {
      return translations.recommendationText
    }

    return null
  }

  const footerText = getFooterText()

  const getLinkText = () => {
    if (isRecommendation) return translations.checkoutLink
    if (isOneClickBooking) return translations.bookAgainLink
    if (isOffer) return translations.offerLink
    return translations.detailsLink
  }

  const getCheckoutLink = () => {
    if (!canBeBookedAgain) {
      return ROUTES.account.bookingDetails(id)
    }

    const checkout = `${ROUTES.serviceFlow({
      flowStep: serviceFlowSteps.checkout,
      booleanParam: "openAppointments",
    })}&utm_source=one_click_booking`

    return isRecommendation
      ? `${checkout}&utm_medium=wheel_change_recommendation`
      : checkout
  }

  const linkText = getLinkText()
  const href = getCheckoutLink()

  const trackRebookingClick = () => {
    sendTrackingEvent.account({
      gaEvent: {
        action: isRecommendation
          ? "wheel_change_recommendation_link"
          : "one_click_booking_link",
        value: services.map((service) => service.name),
      },
    })
  }

  const setFlowData = () => {
    if (!canBeBookedAgain) return

    const serviceFlowData = mapBookingInfoToServiceFlowData(
      garage,
      services,
      car,
    )

    setServiceFlowData(serviceFlowData)
    trackRebookingClick()
  }

  return (
    <BaseCard href={href} noMargin className={bem()}>
      {isRecommendation && (
        <CardTag className={bem("tag")} label={translations.tag} />
      )}

      <BookingCardHeader status={status} />

      <BookingCardServices
        isCancelled={getIsBookingCancelled(status)}
        services={services}
        inquiry={inquiry}
      />

      <span className={bem("garage")}>{garage.name}</span>

      <div className={bem("footer")}>
        <span className={bem("footer-text")}>{footerText}</span>

        <Button
          variant="tertiary"
          className={bem("link")}
          href={href}
          onClick={setFlowData}
        >
          {linkText}
          {isOffer && <Icon className={bem("icon")} name="navNext" />}
        </Button>
      </div>
    </BaseCard>
  )
}
