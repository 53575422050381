import Head from "next/head"
import { useRouter } from "next/router"
import { FC } from "react"

import { useEnvironmentVariables } from "../providers/EnvironmentVariablesProvider"
import { APP_ICONS, EXTERNAL_SERVICES, ROUTES } from "src/config"
import { ContentFulRobotsOption } from "src/types/contentful/autoservice"

const defaultImage = APP_ICONS.app["192x192"].src

export interface MetaProps {
  title?: string
  description?: string
  ogImage?: string
  ogUrl?: string
  robots?: ContentFulRobotsOption
  canonicalPath?: string
}

export const Meta: FC<MetaProps> = (props) => {
  const { environment } = useEnvironmentVariables()
  const shouldIndex = environment === "prod"
  const router = useRouter()

  const title = [props.title].filter(Boolean).join(" | ")
  const description = props.description
  const image = props.ogImage || defaultImage
  const robots = shouldIndex
    ? props.robots || "index, follow"
    : "noindex nofollow"
  // TODO prepend origin, canonical URLs should be absolute
  const canonicalUrl = props.canonicalPath || router.asPath?.split(/[?#]/g)[0]

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <link
        rel="manifest"
        href={ROUTES.api.appManifest}
        crossOrigin="use-credentials"
      />

      {/* Appearance */}
      <meta name="theme-color" content="#ffffff" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />

      {/* Icons */}
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href={APP_ICONS.favIco.src}
      />
      {Object.entries(APP_ICONS.favIcons).map(([size, { src }], index) => {
        return (
          <link
            key={index}
            rel="icon"
            type="image/png"
            sizes={size}
            href={src}
          />
        )
      })}
      {Object.entries(APP_ICONS.appleTouch).map(([size, { src }], index) => {
        return (
          <link key={index} rel="apple-touch-icon" sizes={size} href={src} />
        )
      })}

      {/* OG/Twitter */}
      <meta property="og-title" content={title} />
      <meta property="og-description" content={description} />
      <meta property="og-image" content={image} />
      <meta property="og-url" content={props.ogUrl || canonicalUrl} />
      <meta property="twitter:card" content="summary_large_image" />

      {/* SEO */}
      <meta name="robots" content={robots} />
      <link rel="canonical" href={canonicalUrl} />

      {/*  META verification*/}
      <meta
        name="facebook-domain-verification"
        content={EXTERNAL_SERVICES.metaVerificationCode}
      />
    </Head>
  )
}
